import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import Container from '../components/Container';
import SEO from '../components/seo';
import Logo from '../components/Logo';
import { HeroHeading, SmallTitle, BodyText } from '../components/Global';
import Spin from '../components/Spin';
import theme from '../styles/theme';
import generalHelper from '../utils/general';


const PageWrap = styled.div`
  position: relative;
  text-align: center;
  min-height: 100vh;
  padding: 74px 0;
  width: 100%;
  overflow-x: hidden;

  .first {
    transform: ${props => (props.ts ? 'translateX(-100%)' : 'none')};
  }

  .logo {
    margin-bottom: 33px;
    img {
      margin-bottom: 0;
      margin-right: 16px;
      vertical-align: middle;
    }
    h3 {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
    }
  }
  h1 {
    font-size: 44px;
    line-height: 52.8px;
  }

  p {
    width: 100%;
    max-width: 390px;
    margin: auto;
  }

  .bottom-text {
    width: 100%;
    max-width: 349px;
    margin: auto;
    font-size: 12px;
    line-height: 14.4px;

    a {
      color: ${theme.textColor.blueText};
      text-decoration: none;
      margin: 0 3px;
    }
  }

  .feature {
    margin: 20px auto;
    display: flex;
    align-items: center;
    max-width: 460px;
    text-align: left;
    p {
      max-width: 100%;
      margin-left: 10px;
    }
  }

  form {
    width: 100%;
    max-width: 453px;
    margin: 25px auto;

    input[type="email"] {
      width: 100%;
      margin-bottom: 22px;
      padding: 16px 20px 17px;
      border-radius: 6px;
      border: 1px solid ${theme.borderColor.silver};
      outline: none;
    }
    input[type="checkbox"] {
      margin-right: 7px;
      margin-top: -2px;
      vertical-align: middle;
    }
    label {
      vertical-align: middle;
      font-size: 16px;
      line-height: 1;
    }
  }

  button {
    background: ${theme.hover.hoverBlue};
    margin: 22px auto 21px;
    padding: 10.5px 24px 12px;
    min-width: 120px;
    color: ${theme.heading.dark};
    border-radius: 20px;
    border: 0px;
    outline: none;

    &:hover,
    &:active,
    &:focus {
      background-color: ${theme.hover.hoverLight};
      cursor: pointer;
    }
  }

  .second {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    padding: 74px 0;
    transform: ${props => (props.ts ? 'translateX(-100%)' : 'none')};

    .description {
      margin-bottom: 35px;
    }
  }
`;

const EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


const GetStartedPage = () => {
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);


  const submitFunction = () => {
    const isValidEmail = EMAIL.test(userEmail);
    if (!isValidEmail) {
      return;
    }

    setLoading(true);
    trackCustomEvent({
      category: 'Get started',
      action: 'Add email',
      label: userEmail,
    });
    const dataLayerPush = {
      event: 'Added email to get started',
      visitorEmail: userEmail,
      subscribe: checked
    };
    if (!generalHelper.isWindowUndefined()) {
      if (window.ga) {
        window.ga((tracker) => {
          dataLayerPush.visitorId = tracker.get('clientId');
        });
      }
      if (window.dataLayer) {
        window.dataLayer.push(dataLayerPush);
      }
    }
  };


  return (
    <PageWrap>
      <SEO
        title="Get Started | Workast - Sign Up for Workast for Free in a Few Clicks"
        description="Quickly sign up to Workast to get your team on the way to getting more done, and spending less time managing tasks and projects. Sign up today."
      />
      <div className="first">
        <Container>
          <div className="logo">
            <Logo />
            <SmallTitle>Workast</SmallTitle>
          </div>
          <HeroHeading mb="22px">
            Get started
          </HeroHeading>
          <BodyText mb="22px">
            First, enter your work email address
          </BodyText>
          <form
            action="https://my.workast.com/login/email"
            method="POST"
          >
            <div>
              <input type="email" name="email" placeholder="Email" onChange={e => setUserEmail(e.target.value)} required />
            </div>
            <div>
              <label htmlFor="send-email">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                It’s okay to send me emails about Workast.
              </label>
            </div>
            <div>
              <button type="submit" onClick={submitFunction}>
                {loading && userEmail ? (<Spin />) : (<>Continue</>)}
              </button>
            </div>
          </form>
          <div className="bottom-text">
            By continuing, you are accepting and agreeing to our
            <Link to="/terms-of-service/">Terms of Service</Link>
            and
            <Link to="/privacy-policy/">Privacy Policy.</Link>
          </div>
        </Container>
      </div>
    </PageWrap>
  );
};

export default GetStartedPage;
